<template>
  <div class="about-page">
    <div class="top-banner">
      <img
        class="pc"
        :src="require('../assets/image/about_banner.webp')"
        alt=""
        @load="imgLoad"
      />
      <img
        class="h5"
        :src="require('../assets/image/iphone/about_banner.png')"
        alt=""
      />
      <div class="base-container">
        <div class="banner-content">
          <div class="banner-title">关于我们</div>
          <div class="banner-desc">一家懂业务的金融科技公司</div>
        </div>
      </div>
    </div>
    <div class="nav-bar-wrapper">
      <div class="nav-bar" id="nav-bar-id">
        <div class="base-container">
          <div class="nav-bar-list-wrapper">
            <div
              :class="`nav-bar-list ${
                activeKey === item.key ? 'nav-bar-list-active' : ''
              }`"
              v-for="item in navBarList"
              :key="item.key"
              @click="navBarClick(item.key)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="base-container about-page-container">
      <div class="overview-wrapper" id="overview">
        <div class="overview-box">
          <div class="overview-box-left">
            <div class="overview-title">孚厘概述</div>
            <div class="overview-desc">
              <p>
                孚厘科技成立于2015年9月，公司总部设立在上海，陆续在江苏、安徽、广东、湖北、北京、深圳等地设立了十余家分公司。
              </p>
              <p>
                深耕于小微业务，坚持业务实践，拥有丰富的业务实战经验。公司具有纯正的台州模式基因，涵盖金融及科技板块。
              </p>
              <p>
                科技板块主打“升级版台州模式”，是国内领先的小微金融解决方案提供商。面向政府平台、城商行、农商行、村镇银行等提供小微金融输出方案，以系统搭建、咨询、联合运营等形式，帮助合作方搭建完善的小微信贷体系，定制涵盖小微金融市场战略、管理模式、信贷文化、团队建设、风控技术等全流程全方位的金融方案。
              </p>
            </div>
          </div>
          <div class="overview-box-right"></div>
        </div>
        <div class="overview-list-wrapper">
          <div class="overview-list">
            <div class="overview-list-title">使命</div>
            <div class="overview-list-desc">帮小微企业活得更好</div>
          </div>
          <div class="overview-list">
            <div class="overview-list-title">企业文化</div>
            <div class="overview-list-desc">简单 卓越 不走捷径</div>
          </div>
          <div class="overview-list">
            <div class="overview-list-title">价值观</div>
            <div class="overview-list-desc">永远走正道</div>
          </div>
        </div>
      </div>
      <div class="team-wrapper" id="team">
        <div class="base-title team-title">团队风采</div>
        <div class="team-desc">
          孚厘团队来自平均从业10年以上泰隆银行、台州银行背景小微贷款专家
        </div>
        <div class="team-list-wrapper pc">
          <div class="team-list-top">
            <div class="top-left-img">
              <img :src="require('../assets/image/team-list1.png')" alt="" />
            </div>
            <div class="top-right-img">
              <img :src="require('../assets/image/team-list2.png')" alt="" />
            </div>
          </div>
          <div class="team-list-bottom">
            <div class="team-bottom-list team-bottom-list1">
              <img :src="require('../assets/image/team-list3.png')" alt="" />
            </div>
            <div class="team-bottom-list team-bottom-list2">
              <img :src="require('../assets/image/team-list4.png')" alt="" />
            </div>
            <div class="team-bottom-list">
              <img :src="require('../assets/image/team-list5.png')" alt="" />
            </div>
          </div>
        </div>
        <div class="team-list-wrapper h5">
          <div class="team-list-top">
            <div class="top-left-img">
              <img
                :src="require('../assets/image/iphone/team-list1.png')"
                alt=""
              />
            </div>
            <div class="top-right-img">
              <img
                :src="require('../assets/image/iphone/team-list2.png')"
                alt=""
              />
            </div>
          </div>
          <div class="team-list-bottom">
            <div class="team-bottom-list">
              <img
                :src="require('../assets/image/iphone/team-list3.png')"
                alt=""
              />
            </div>
            <div class="team-bottom-list">
              <img
                :src="require('../assets/image/iphone/team-list4.png')"
                alt=""
              />
            </div>
            <div class="team-bottom-list">
              <img
                :src="require('../assets/image/iphone/team-list5.png')"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="base-container about-page-container">
      <div class="invest-wrapper" id="invest">
        <div class="base-title">投资机构</div>
        <div class="invest-list-wrapper">
          <div class="invest-list" v-for="item in investList" :key="item.title">
            <div class="invest-list-top">
              <img class="pc" :src="item.topImg" alt="" />
              <img class="h5" :src="item.topH5Img" alt="" />
            </div>
            <div class="invest-list-bottom">
              <div class="invest-list-title">
                {{ item.title }}
              </div>
              <div class="invest-list-desc">
                {{ item.desc }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="experience-wrapper pc" id="experience">
        <div class="base-title">发展历程</div>
        <div class="experience-content">
          <div class="experience-content-bar">
            <div class="left-arrow" @click="arrowClick('left')">
              <img
                :src="require('../assets/image/experience_left_arrow.png')"
                alt=""
              />
            </div>
            <div class="experience-content-center">
              <div
                :class="`year-list ${
                  activeYear === item.year ? 'active-year-list' : ''
                }`"
                v-for="item in groupExperienceList[activeIndex]"
                :key="item.year"
                @mouseenter="changeActiveYear(item.year)"
              >
                {{ item.year }}
              </div>
            </div>
            <div class="right-arrow" @click="arrowClick('right')">
              <img
                :src="require('../assets/image/experience_right_arrow.png')"
                alt=""
              />
            </div>
          </div>
          <div class="tab-content">
            <div
              class="tab-list"
              v-for="item in activeMonthList"
              :key="item.month"
            >
              <div class="month">
                <div class="month-text">{{ item.month }}</div>
              </div>
              <div class="tab-list-box-wrapper">
                <div
                  class="tab-list-box"
                  v-for="(monthItem, index) in item.children"
                  :key="index"
                >
                  <div class="line"></div>
                  <div class="dot"></div>
                  <div class="tab-list-right">
                    <div class="tab-right-content">
                      {{ monthItem }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="experience-wrapper h5">
        <div class="base-title">发展历程</div>
        <div class="experience-content">
          <div class="experience-content-bar">
            <div class="left-arrow" @click="h5ArrowClick('left')">
              <img
                :src="require('../assets/image/experience_left_arrow.png')"
                alt=""
              />
            </div>
            <div class="experience-content-center">
              <div
                class="year-list active-year-list"
                @mouseenter="h5ChangeActiveYear()"
              >
                {{ experienceList[h5ActiveIndex].year }}
              </div>
            </div>
            <div class="right-arrow" @click="h5ArrowClick('right')">
              <img
                :src="require('../assets/image/experience_right_arrow.png')"
                alt=""
              />
            </div>
          </div>
          <div class="tab-content">
            <div
              class="tab-list"
              v-for="item in experienceList[h5ActiveIndex].children"
              :key="item.month"
            >
              <div class="month">
                <div class="month-text">{{ item.month }}</div>
              </div>
              <div class="tab-list-box-wrapper">
                <div
                  class="tab-list-box"
                  v-for="(monthItem, index) in item.children"
                  :key="index"
                >
                  <div class="line"></div>
                  <div class="dot"></div>
                  <div class="tab-list-right">
                    <div class="tab-right-content">
                      {{ monthItem }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="approve-wrapper pc" id="approve">
        <div class="base-title">社会认可</div>
        <div class="approve-list-wrapper">
          <div
            class="approve-list"
            v-for="item in approveList"
            :key="item.title"
          >
            <div class="approve-list-top">
              <img :src="item.imgUrl" alt="" />
            </div>
            <div class="approve-list-bottom">
              <div class="approve-list-title">{{ item.title }}</div>
            </div>
          </div>
          <div class="approve-list"></div>
        </div>
      </div>
      <div class="approve-wrapper h5">
        <div class="base-title">社会认可</div>
        <div class="approve-list-wrapper">
          <template v-for="item in h5ApproveList">
            <div class="approve-list" :key="item.title">
              <div class="approve-list-top">
                <img :src="item.imgUrl" alt="" />
              </div>
              <div class="approve-list-bottom">
                <div class="approve-list-title">{{ item.title }}</div>
              </div>
            </div>
          </template>
        </div>
        <div class="approve-list-more" v-if="!isMore" @click="moreApproveList">
          加载更多
        </div>
        <div class="approve-list-more" v-else @click="moreApproveList">
          收起
        </div>
      </div>
      <div class="contact-wrapper" id="contact">
        <div class="base-title">联系我们</div>
        <div class="contact-list-wrapper">
          <div class="contact-list">
            <div class="contact-list-title">总部地址</div>
            <div class="contact-list-desc">
              上海市徐汇区龙耀路175号星扬西岸中心27楼09室
            </div>
          </div>
          <div class="contact-list">
            <div class="contact-list-title">客服电话</div>
            <div class="contact-list-desc">021-50871163</div>
          </div>
          <div class="contact-list">
            <div class="contact-list-title">行政联系</div>
            <div class="contact-list-desc">contact@magfin.cn</div>
          </div>
          <div class="contact-list">
            <div class="contact-list-title">客服邮箱</div>
            <div class="contact-list-desc">kefu@magfin.cn</div>
          </div>
        </div>
      </div>
      <div class="affiliate-wrapper" id="affiliate">
        <div class="base-title">分支机构</div>
        <div class="affiliate-list-wrapper">
          <div
            class="affiliate-list"
            v-for="item in affiliateList"
            :key="item.name"
          >
            <div class="affiliate-list-title">{{ item.name }}</div>
            <div class="affiliate-list-address">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
  data() {
    return {
      activeKey: "overview",
      navBarList: [
        {
          title: "孚厘概述",
          key: "overview",
        },
        {
          title: "团队风采",
          key: "team",
        },
        {
          title: "投资机构",
          key: "invest",
        },
        {
          title: "发展历程",
          key: "experience",
        },
        {
          title: "社会认可",
          key: "approve",
        },
        {
          title: "联系我们",
          key: "contact",
        },
        {
          title: "分支机构",
          key: "affiliate",
        },
      ],
      investList: [
        {
          title: "宽带资本",
          desc: "宽带资本（CBC）成立于2006年，10于年来专注于TMT投资，被视为中国互联网和技术领域最具有影响力的投资公司之一。主要投资包括孚厘科技、同盾科技、大众点评、分众传媒、东方明珠、喜马拉雅以及挖财等行业领先企业。",
          topImg: require("../assets/image/invest_list1.png"),
          topH5Img: require("../assets/image/iphone/invest_list1.png"),
        },
        {
          title: "云启资本",
          desc: "云启资本创立于2014年，被列入2021中国独角兽投资机构Top 30。管理一支美元基金，两支人民币基金，合计20亿人民币。投资领域覆盖人工智能大数据、企业SaaS及服务、B2B供应链平台、智联设备、先进制造等行业，主要投资包括孚厘科技、360金融、英科医疗、酷家乐等知名企业。",
          topImg: require("../assets/image/invest_list2.png"),
          topH5Img: require("../assets/image/iphone/invest_list2.png"),
        },
        {
          title: "源码资本",
          desc: "源码资本创立于2014年，资金规模约350亿元人民币，致力于发现并助力科技推动商业变革中的世界级领军企业。在促进科技创新、推动绿色发展等领域投资了超过300家创业企业，主要投资包括孚厘科技、字节、美团、理想汽车等知名企业。",
          topImg: require("../assets/image/invest_list3.png"),
          topH5Img: require("../assets/image/iphone/invest_list3.png"),
        },
        {
          title: "经纬中国",
          desc: "经纬中国是行业内长期专注早期和早成长期投资的头部创投机构。2021 年入选艾媒金榜（iiMedia Ranking）发布的《中国投资机构百强榜(2021)》，总资金规模超650亿元，主要投资孚厘科技、新氧、滴滴出行、陌陌科技、饿了么、理想汽车、小鹏汽车等知名企业。",
          topImg: require("../assets/image/invest_list4.png"),
          topH5Img: require("../assets/image/iphone/invest_list4.png"),
        },
      ],
      navBarHeight: 0,
      experienceList: [
        {
          year: "2024",
          children: [
            {
              month: "2月",
              children: ["智能微贷产品全新升级"],
            },
            {
              month: "3月",
              children: ["“太阿流水解析平台”V3.0发布"],
            },
            {
              month: "7月",
              children: [
                "“星宫网格化营销系统”新产品发布",
                "“小融e贷”新产品发布",
              ],
            },
          ],
        },
        {
          year: "2023",
          children: [
            {
              month: "1月",
              children: [
                "旗下晋福担保荣获最佳金融奖",
                "荣获科技创新奖",
                "荣获开放服务奖",
              ],
            },
            {
              month: "3月",
              children: ["荣获上海市“专精特新”中小企业称号"],
            },
            {
              month: "6月",
              children: ["在小微企业风险控制领域取得一项国家发明专利授权"],
            },
            {
              month: "11月",
              children: ["总部乔迁至上海市徐汇区星扬西岸中心"],
            },
          ],
        },
        {
          year: "2022",
          children: [
            {
              month: "1月",
              children: ["入选“2021小微金融数字服务商TOP30”榜单"],
            },
            {
              month: "2月",
              children: ["重庆两江分公司成立"],
            },
            {
              month: "5月",
              children: [
                "在大数据贷款逾期预测分析领域获取一项国家发明专利授权",
              ],
            },
            {
              month: "9月",
              children: ["深圳分公司成立"],
            },
            {
              month: "11月",
              children: [
                "荣获上海市宝山区企业技术中心",
                "与桂林银行等10多家银行达成合作",
              ],
            },
            {
              month: "12月",
              children: ["国家高新技术企业复审通过"],
            },
          ],
        },
        {
          year: "2021",
          children: [
            {
              month: "5月",
              children: ["北京分公司成立"],
            },
            {
              month: "9月",
              children: ["荣获银行家杂志举办的2021中国金融创新奖"],
            },
            {
              month: "10月",
              children: [
                "获得质量管理体系认证证书",
                "获得信息安全管理体系认证证书",
              ],
            },
            {
              month: "12月",
              children: ["在计算机科学领域获得一项国家发明专利授权"],
            },
          ],
        },
        {
          year: "2020",
          children: [
            {
              month: "5月",
              children: [
                "旗下晋福担保获人民银行A类征信评级",
                "上海宝山区月浦镇政府授予“2020年度经济社会发展最佳金融企业奖”",
                "第一财财经股权投资价值榜“年度非标小微信贷科技服务机构”",
              ],
            },
          ],
        },
        {
          year: "2019",
          children: [
            {
              month: "5月",
              children: ["参与第二期云启资本媒体对接会"],
            },
            {
              month: "9月",
              children: [
                "收购上海晋福融资担保有限公司",
                "上海分公司成立",
                "重庆分公司成立",
              ],
            },
            {
              month: "11月",
              children: [" 获评全国青年创业者大会年度最具成长性企业"],
            },
          ],
        },
        {
          year: "2018",
          children: [
            {
              month: "4月",
              children: [
                "安徽分公司成立",
                "与银联商务全资控股子公司达成战略合作",
              ],
            },
            {
              month: "7月",
              children: ["湖南分公司成立"],
            },
            {
              month: "8月",
              children: ["入选B2B产业发展联盟会员单位"],
            },
            {
              month: "9月",
              children: ["四川分公司成立", "完成1.2亿元B轮融资"],
            },
            {
              month: "10月",
              children: ["广东分公司成立"],
            },
            {
              month: "12月",
              children: [
                "武汉公司成立",
                "荣获中国金融创新榜最佳泛供应链金融机构",
              ],
            },
          ],
        },
        {
          year: "2017",
          children: [
            {
              month: "3月",
              children: [
                "完成6500万元A轮融资",
                "无锡、江苏分公司开业，保理公司成立",
                "荣登全国《供应链金融生态图谱》金融服务平台类标杆模式",
              ],
            },
            {
              month: "4月",
              children: ["荣膺2017 中国供应链金融年会最佳供应商奖"],
            },
            {
              month: "10月",
              children: [
                "湖北分公司成立",
                "荣获“2017年中国B2B企业服务商30强”",
                "自主研发的“湛泸风控系统”正式上线",
              ],
            },
          ],
        },
        {
          year: "2016",
          children: [
            {
              month: "1月",
              children: ["获得天使轮融资"],
            },
            {
              month: "3月",
              children: ["与中国银行等10家银行机构建立战略合作"],
            },
            {
              month: "4月",
              children: ["与南洋银行商业银行战略合作"],
            },
            {
              month: "5月",
              children: ["与万达金融签订战略合作协议"],
            },
            {
              month: "8月",
              children: ["信贷业务余额破1亿"],
            },
            {
              month: "9月",
              children: ["当月产能破亿完成小目标"],
            },
            {
              month: "11月",
              children: ["斩获由第一财经发起的  年度小微金融机构奖"],
            },
            {
              month: "12月",
              children: ["贷款余额总额突破5亿大关"],
            },
          ],
        },
        {
          year: "2015",
          children: [
            {
              month: "9月",
              children: ["公司成立"],
            },
          ],
        },
      ],
      activeYear: "2024",
      activeIndex: 0,
      groupExperienceList: [],
      approveList: [
        {
          imgUrl: require("../assets/image/approve-list1.png"),
          title: "国家高新技术企业",
        },
        {
          imgUrl: require("../assets/image/approve-list2.png"),
          title: "上海市专精特新中小企业",
        },
        {
          imgUrl: require("../assets/image/approve-list3.png"),
          title: "质量管理ISO9001认证",
        },
        {
          imgUrl: require("../assets/image/approve-list4.png"),
          title: "质量管理ISO27001认证",
        },
        {
          imgUrl: require("../assets/image/approve-list5.png"),
          title: "2022年宝山区企业技术中心",
        },
        {
          imgUrl: require("../assets/image/approve-list6.png"),
          title: "最佳金融奖",
        },
        {
          imgUrl: require("../assets/image/approve-list7.png"),
          title: "开放服务奖",
        },
        {
          imgUrl: require("../assets/image/approve-list8.png"),
          title: "科技创新奖",
        },
        {
          imgUrl: require("../assets/image/approve-list9.png"),
          title: "零壹财经零壹智库“小微金融数字服务商TOP30”",
        },
        {
          imgUrl: require("../assets/image/approve-list10.png"),
          title: "银行家2021年中国金融创新奖“十佳普惠金融科技创新奖”",
        },
        {
          imgUrl: require("../assets/image/approve-list11.5.png"),
          title: "上海宝山区月浦镇2020年度最佳金融企业奖",
        },
        {
          imgUrl: require("../assets/image/approve-list11.png"),
          title: "上海市宝山区人民政府授予“宝山区2019年度经济发展突出贡献奖”",
        },
        {
          imgUrl: require("../assets/image/approve-list12.png"),
          title: "上海市宝山区人民政府授予“宝山区2019年度最佳成长企业”",
        },
        {
          imgUrl: require("../assets/image/approve-list13.png"),
          title: "2019年上海台州商会金融行业杰出奖",
        },
        {
          imgUrl: require("../assets/image/approve-list14.png"),
          title: "小饭桌2019年度最具成长性企业",
        },
        {
          imgUrl: require("../assets/image/approve-list15.png"),
          title: "创业邦“2018年中国创新成长企业100强”企业",
        },
        {
          imgUrl: require("../assets/image/approve-list16.png"),
          title: "第一财经中国金融创新榜“最佳泛供应链小微金融机构”",
        },
        {
          imgUrl: require("../assets/image/approve-list17.png"),
          title: "B2B产业发展联盟会员单位",
        },
        {
          imgUrl: require("../assets/image/approve-list18.png"),
          title: "B2B行业协会会员单位",
        },
        {
          imgUrl: require("../assets/image/approve-list19.png"),
          title: "B2B行业协会理事单位",
        },
        {
          imgUrl: require("../assets/image/approve-list20.png"),
          title: "2018最佳泛供应链小微金融机构",
        },
        {
          imgUrl: require("../assets/image/approve-list21.png"),
          title:
            "第二届中国B2B峰会暨首届中国B2B博览会“2017年中国B2B企业服务商30强”",
        },
        {
          imgUrl: require("../assets/image/approve-list22.png"),
          title: "2017年中国年度最佳雇主",
        },
        {
          imgUrl: require("../assets/image/approve-list23.png"),
          title: "2017年供应链金融行业标兵",
        },
        {
          imgUrl: require("../assets/image/approve-list24.png"),
          title: "2016年度小微金融创新机构",
        },
      ],
      affiliateList: [
        {
          name: "上海总部",
          desc: "地址：上海市徐汇区龙耀路175号星扬西岸中心27楼09室",
        },
        {
          name: "北京分公司",
          desc: "地址：北京市顺义区仁和镇林河南大街9号高顺云港新 能科技园一号楼 401",
        },
        {
          name: "深圳分公司",
          desc: "地址：广东省深圳市南山区卓越前海壹号A座609",
        },
        {
          name: "广东分公司",
          desc: "地址：广州市天河区东圃镇二马路67号盈科智谷23栋402",
        },
        {
          name: "江苏分公司",
          desc: "地址：江苏省苏州市工业园区东环路1408号东环 时代广场601室",
        },
        {
          name: "安徽分公司",
          desc: "地址：安徽省合肥市蜀山区潜山路与休宁路交口中侨中心 C座1310室",
        },
        {
          name: "湖北分公司",
          desc: "地址：武汉市江汉区ICC环贸中心B座603室",
        },
        {
          name: "重庆分公司",
          desc: "地址：重庆市渝北区新溉大道99号中渝香奈公馆 7栋28楼",
        },
        {
          name: "重庆两江分公司",
          desc: "地址：重庆市渝北区新溉大道99号中渝香奈公馆 7栋28楼",
        },
        {
          name: "四川分公司",
          desc: "地址：四川省成都市高新区益州大道北段362号安格斯 国际中心1楼110号",
        },
        {
          name: "湖南分公司",
          desc: "地址：长沙市天心区芙蓉南路一段979号 天城国际广场8栋705室",
        },
        {
          name: "江西分公司",
          desc: "地址：江西省南昌市红谷滩新区丽景路95号出版中心1408室",
        },
      ],
      h5ActiveIndex: 0,
      isMore: false,
    };
  },
  mounted() {
    this._initExperienceList();
    // 获取小的navbar的高度
    this.navBarHeight = document.getElementById("nav-bar-id").offsetHeight;
    window.addEventListener("scroll", this.scrollChange);
  },
  computed: {
    activeMonthList() {
      return this.experienceList.find((item) => item.year === this.activeYear)
        .children;
    },
    h5ApproveList() {
      return this.isMore ? this.approveList : this.approveList.slice(0, 9);
    },
  },
  methods: {
    imgLoad() {
      // 获取小的navbar具体顶部的距离
      this.navBarScrollTop =
        document.getElementById("nav-bar-id").getBoundingClientRect().top +
        this.getScrollTop();
    },
    // 移动端加载更多
    moreApproveList() {
      this.isMore = !this.isMore;
    },
    scrollChange() {
      // 获取当前锚点所有的dom
      const boxListDom = this.navBarList.map((item) =>
        document.getElementById(item.key)
      );
      // 获取当前锚点所有的scrollTop
      const boxListTop = boxListDom.map((item) =>
        Math.floor(
          item.getBoundingClientRect().top +
            this.getScrollTop() -
            this.navBarHeight
        )
      );
      let scrollTop = this.getScrollTop();
      // 根据滚动高度大导航栏消失
      if (scrollTop > 80) {
        let topNavBarClass = document.getElementById("top-nav-bar").className;
        if (topNavBarClass.indexOf("hidden-nav-bar") === -1) {
          document.getElementById("top-nav-bar").className += " hidden-nav-bar";
        }
      } else {
        document.getElementById("top-nav-bar").className = "nav-bar";
      }
      // 根据滚动高度小导航栏绝对定位
      if (scrollTop > this.navBarScrollTop) {
        const currentNavBarClass =
          document.getElementById("nav-bar-id").className;
        if (currentNavBarClass.indexOf("fixed-nav-bar") === -1) {
          document.getElementById("nav-bar-id").className += " fixed-nav-bar";
        }
      } else {
        document.getElementById("nav-bar-id").className = "nav-bar";
      }
      if (scrollTop >= boxListTop[boxListTop.length - 1] - 100) {
        this.activeKey = this.navBarList[boxListTop.length - 1].key;
      } else if (scrollTop >= 0 && scrollTop < boxListTop[1]) {
        this.activeKey = this.navBarList[0].key;
      } else {
        boxListTop.forEach((item, index) => {
          if (scrollTop >= item && scrollTop < boxListTop[index + 1]) {
            this.activeKey = this.navBarList[index].key;
          }
        });
      }
    },
    _initExperienceList() {
      let groupExperienceList = [];
      for (let i = 0; i < this.experienceList.length; i += 5) {
        const arr = this.experienceList.slice(i, i + 5);
        groupExperienceList.push(arr);
      }
      this.groupExperienceList = groupExperienceList;
    },
    h5ChangeActiveYear() {},
    h5ArrowClick(type) {
      if (type === "left") {
        if (this.h5ActiveIndex === 0) {
          this.h5ActiveIndex = this.experienceList.length - 1;
        } else {
          this.h5ActiveIndex -= 1;
        }
      } else if (type === "right") {
        if (this.h5ActiveIndex === this.experienceList.length - 1) {
          this.h5ActiveIndex = 0;
        } else {
          this.h5ActiveIndex += 1;
        }
      }
    },
    changeActiveYear(year) {
      this.activeYear = year;
    },
    arrowClick(type) {
      if (type === "left") {
        if (this.activeIndex === 0) {
          this.activeIndex = this.groupExperienceList.length - 1;
        } else {
          this.activeIndex -= 1;
        }
      } else if (type === "right") {
        if (this.activeIndex === this.groupExperienceList.length - 1) {
          this.activeIndex = 0;
        } else {
          this.activeIndex += 1;
        }
      }
      this.activeYear = this.groupExperienceList[this.activeIndex][0].year;
    },
    getScrollTop() {
      let scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      return scrollTop;
    },
    navBarClick(key) {
      this.activeKey = key;
      let scrollTop =
        this.getScrollTop() +
        document.getElementById(key).getBoundingClientRect().top;
      document.documentElement.scrollTop = scrollTop - this.navBarHeight;
      document.body.scrollTop = scrollTop - this.navBarHeight;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollChange);
  },
};
</script>

<style scoped lang="scss">
.about-page {
  .top-banner {
    position: relative;
    width: 100%;
    font-size: 0px;
    img {
      width: 100%;
      height: auto;
    }
    .base-container {
      position: absolute;
      left: 50%;
      top: 0px;
      height: 100%;
      transform: translateX(-50%);
    }
    .banner-content {
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
    }
    .banner-title {
      font-size: 48px;
      font-weight: bold;
      color: #fffcfc;
      line-height: 52px;
    }
    .banner-desc {
      font-size: 20px;
      font-weight: bold;
      color: #fffcfc;
      line-height: 26px;
      margin-top: 20px;
    }
  }
  .about-page-container {
    .base-title {
      font-size: 36px;
      font-weight: bold;
      color: #333333;
      line-height: 50px;
      text-align: center;
      margin-top: 80px;
      margin-bottom: 48px;
    }
    .overview-wrapper {
      margin-top: 80px;
      .overview-box {
        display: flex;
        .overview-box-left {
          width: 624px;
          height: 642px;
          background: #f5f7fa;
          padding: 54px 69px;
          box-sizing: border-box;
          .overview-title {
            position: relative;
            display: inline-block;
            height: 39px;
            font-size: 28px;
            font-weight: bold;
            color: #333333;
            line-height: 33px;
            padding-bottom: 16px;
            &:before {
              position: absolute;
              bottom: 0;
              left: 0;
              content: "";
              display: block;
              width: 100%;
              height: 1px;
              background: linear-gradient(
                to right,
                #db2534 0%,
                #db2534 30%,
                #4782f7 30%,
                #4782f7 100%
              );
            }
          }
          .overview-desc {
            margin-top: 54px;
            p {
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              line-height: 24px;
              margin-top: 0px;
              margin-bottom: 40px;
            }
          }
        }
        .overview-box-right {
          flex: 1;
          background-size: 100%;
          background-image: url("../assets/image/overview_right_bg.png");
        }
      }
      .overview-list-wrapper {
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
        .overview-list {
          width: 380px;
          height: 201px;
          background: #f5f7fa;
          border-radius: 10px;
          padding: 53px 53px;
          box-sizing: border-box;
          .overview-list-title {
            font-size: 20px;
            font-weight: bold;
            color: #999999;
            line-height: 26px;
            margin-bottom: 15px;
          }
          .overview-list-desc {
            font-size: 28px;
            font-weight: bold;
            color: #333333;
            line-height: 33px;
          }
        }
      }
    }
    .invest-wrapper {
      .invest-list-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .invest-list {
          width: 591px;
          height: 323px;
          background: #ffffff;
          border-radius: 10px;
          border: 1px solid #e7e7e7;
          overflow: hidden;
          .invest-list-top {
            height: 127px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .invest-list-bottom {
            padding: 20px 36px 0px;
            box-sizing: border-box;
            height: 189px;
            .invest-list-title {
              font-size: 18px;
              font-weight: bold;
              color: #333333;
              line-height: 24px;
              margin-bottom: 10px;
            }
            .invest-list-desc {
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              line-height: 24px;
            }
          }
          &:nth-child(-n + 2) {
            margin-bottom: 24px;
          }
        }
      }
    }
    .experience-wrapper {
      .experience-content {
        text-align: center;
        .experience-content-bar {
          width: 100%;
          height: 86px;
          display: flex;
          .left-arrow,
          .right-arrow {
            width: 86px;
            height: 86px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .experience-content-center {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0px 19px;
            .year-list {
              flex: 1;
              text-align: center;
              font-size: 24px;
              font-weight: 500;
              color: #666666;
              cursor: pointer;
            }
            .active-year-list {
              font-size: 100px;
              font-weight: 500;
              color: #4782f7;
              line-height: 48px;
            }
          }
        }
        .tab-content {
          margin-top: 10px;
          padding: 47px 0px;
          display: inline-block;
          .tab-list {
            display: flex;
            &:last-child {
              .tab-list-box-wrapper {
                .tab-list-box {
                  &:last-child {
                    .line {
                      display: none;
                    }
                  }
                }
              }
            }
            .month {
              width: 100px;
              text-align: right;
              padding-right: 20px;
              .month-text {
                font-size: 16px;
                font-weight: 400;
                color: #666666;
                line-height: 26px;
                position: relative;
                top: -7px;
              }
            }
            .tab-list-box {
              position: relative;
              padding-bottom: 20px;
              .dot {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 2px solid #0070ff;
                position: absolute;
                left: -1px;
                top: 0px;
                background: #ffffff;
                box-sizing: border-box;
                z-index: 100;
              }
              .line {
                position: absolute;
                left: 4px;
                height: 100%;
                border-left: 2px solid #e4e7ed;
              }
              .tab-list-right {
                position: relative;
                padding-left: 28px;
                .tab-right-content {
                  text-align: left;
                  height: 28px;
                  font-size: 18px;
                  font-weight: 400;
                  color: #333333;
                  line-height: 28px;
                  position: relative;
                  top: -8px;
                }
              }
            }
          }
        }
      }
    }
    .approve-wrapper {
      .approve-list-wrapper {
        display: flex;
        flex-wrap: wrap;
        .approve-list {
          width: 240px;
          border-right: 1px solid #e4e3e3;
          border-bottom: 1px solid #e4e3e3;
          box-sizing: border-box;
          &:nth-child(-n + 5) {
            border-top: 1px solid #e4e3e3;
          }
          &:nth-child(5n + 1) {
            border-left: 1px solid #e4e3e3;
          }
          .approve-list-top {
            width: 100%;
            height: 181px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .approve-list-bottom {
            height: 100px;
            padding: 0px 15px 10px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            justify-content: center;
            .approve-list-title {
              font-size: 14px;
              color: #666666;
              line-height: 24px;
              text-align: center;
            }
          }
        }
      }
    }
    .contact-wrapper {
      .contact-list-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .contact-list {
          width: 586px;
          height: 154px;
          background: #ffffff;
          box-shadow: 0px 0px 26px 0px #dde4f6;
          border-radius: 10px;
          padding: 38px;
          box-sizing: border-box;
          margin-bottom: 30px;
          &:nth-child(n + 3) {
            margin-bottom: 0px;
          }
          .contact-list-title {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 26px;
            margin-bottom: 12px;
          }
          .contact-list-desc {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            line-height: 24px;
          }
        }
      }
    }
    .affiliate-wrapper {
      margin-bottom: 80px;
      .affiliate-list-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .affiliate-list {
          width: 360px;
          margin-bottom: 25px;
          box-sizing: border-box;
          .affiliate-list-title {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            line-height: 24px;
            height: 40px;
            position: relative;
            &:before {
              content: "";
              display: block;
              position: absolute;
              bottom: 0px;
              left: 0px;
              width: 32px;
              height: 1px;
              background: #0070ff;
            }
          }
          .affiliate-list-address {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
            height: 84px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .team-wrapper {
    .base-title {
      font-size: 36px;
      font-weight: bold;
      color: #333333;
      line-height: 50px;
      text-align: center;
      margin-top: 80px;
      margin-bottom: 48px;
    }
    .team-title {
      margin-bottom: 0px;
    }
    .team-desc {
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 26px;
      text-align: center;
      margin-top: 20px;
    }
    .team-list-wrapper {
      margin-top: 48px;
      font-size: 0px;
      padding-bottom: 48px;
      .team-list-top {
        display: flex;
        justify-content: space-between;
        .top-left-img,
        .top-right-img {
          border-radius: 10px;
          overflow: hidden;
          img {
            width: 100%;
            height: auto;
          }
        }
        .top-left-img {
          width: 814px;
        }
        .top-right-img {
          width: 376px;
        }
      }
      .team-list-bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .team-bottom-list {
          width: 402px;
          border-radius: 10px;
          overflow: hidden;
          &:last-child {
            width: 376px;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
        .team-bottom-list1 {
          width: 326px;
        }
        .team-bottom-list2 {
          width: 478px;
        }
      }
    }
  }
  .h5 {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .about-page {
    .top-banner {
      width: 100%;
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
      .base-container {
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        transform: translateX(0);
      }
      .banner-content {
        position: absolute;
        top: 50%;
        left: 115px;
        transform: translateY(-50%);
      }
      .banner-title {
        font-size: 123px;
        line-height: 133px;
      }
      .banner-desc {
        margin-top: 51px;
        font-size: 51px;
        line-height: 67px;
      }
    }
    .nav-bar-wrapper {
      display: none;
    }
    .about-page-container {
      .base-title {
        font-size: 82px;
        line-height: 123px;
        margin-top: 154px;
        margin-bottom: 64px;
      }
      .overview-wrapper {
        margin-top: 80px;
        .overview-box {
          display: flex;
          flex-direction: column-reverse;
          flex-wrap: wrap;
          .overview-box-left {
            width: 100%;
            height: 1249px;
            padding: 97px 74px;
            box-sizing: border-box;
            .overview-title {
              position: relative;
              display: inline-block;
              font-size: 72px;
              font-weight: bold;
              color: #333333;
              padding-bottom: 50px;
              &:before {
                position: absolute;
                bottom: 0;
                left: 0;
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: linear-gradient(
                  to right,
                  #db2534 0%,
                  #db2534 30%,
                  #4782f7 30%,
                  #4782f7 100%
                );
              }
            }
            .overview-desc {
              margin-top: 74px;
              p {
                margin-bottom: 60px;
                font-size: 46px;
                line-height: 72px;
              }
            }
          }
          .overview-box-right {
            flex: none;
            width: 100%;
            height: 1536px;
            background-size: 100% 100%;
            background-image: url("../assets/image/overview_right_bg.png");
          }
        }
        .overview-list-wrapper {
          margin-top: 80px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .overview-list {
            width: 100%;
            height: 515px;
            padding: 153px 136px;
            margin-bottom: 44px;
            .overview-list-title {
              font-size: 51px;
              line-height: 67px;
              margin-bottom: 38px;
            }
            .overview-list-desc {
              font-size: 72px;
              line-height: 84px;
            }
          }
        }
      }
      .invest-wrapper {
        .invest-list-wrapper {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .invest-list {
            width: 863px;
            height: auto;
            background: #ffffff;
            border-radius: 10px;
            border: 1px solid #e7e7e7;
            overflow: hidden;
            .invest-list-top {
              height: 325px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .invest-list-bottom {
              padding: 51px 92px;
              box-sizing: border-box;
              height: auto;
              .invest-list-title {
                font-size: 72px;
                line-height: 84px;
                margin-bottom: 24px;
              }
              .invest-list-desc {
                font-size: 46px;
                line-height: 72px;
              }
            }
            &:nth-child(-n + 2) {
              margin-bottom: 61px;
            }
          }
        }
      }
      .experience-wrapper {
        .experience-content {
          text-align: center;
          .experience-content-bar {
            width: 100%;
            height: 280px;
            display: flex;
            .left-arrow,
            .right-arrow {
              width: 280px;
              height: 280px;
              cursor: pointer;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .experience-content-center {
              flex: 1;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 0px 19px;
              .year-list {
                flex: 1;
                text-align: center;
                font-size: 256px;
                font-weight: 500;
                color: #666666;
                cursor: pointer;
              }
              .active-year-list {
                font-size: 256px;
                font-weight: 500;
                color: #4782f7;
                line-height: 48px;
              }
            }
          }
          .tab-content {
            margin-top: 10px;
            padding: 120px 0px;
            display: inline-block;
            .tab-list {
              display: flex;
              &:last-child {
                .tab-list-box-wrapper {
                  .tab-list-box {
                    &:last-child {
                      .line {
                        display: none;
                      }
                    }
                  }
                }
              }
              .month {
                width: 150px;
                text-align: right;
                padding-right: 54px;

                .month-text {
                  font-size: 41px;
                  line-height: 67px;
                  position: relative;
                  top: -20px;
                }
              }
              .tab-list-box {
                position: relative;
                padding-bottom: 60px;
                .dot {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  border: 2px solid #0070ff;
                  position: absolute;
                  left: -1px;
                  top: 0px;
                  background: #ffffff;
                  box-sizing: border-box;
                  z-index: 100;
                }
                .line {
                  position: absolute;
                  left: 14px;
                  height: 100%;
                  border-left: 2px solid #e4e7ed;
                  width: 0px;
                }
                .tab-list-right {
                  position: relative;
                  padding-left: 68px;
                  .tab-right-content {
                    text-align: left;
                    height: auto;
                    font-size: 46px;
                    line-height: 72px;
                    position: relative;
                    top: -20px;
                  }
                }
              }
            }
          }
        }
      }
      .approve-wrapper {
        .approve-list-wrapper {
          display: flex;
          flex-wrap: wrap;
          .approve-list {
            width: 588px;
            border-right: 1px solid #e4e3e3;
            border-bottom: 1px solid #e4e3e3;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            &:nth-child(-n + 5) {
              border-top: none;
            }
            &:nth-child(5n + 1) {
              border-left: none;
            }
            &:nth-child(-n + 3) {
              border-top: 1px solid #e4e3e3;
            }
            &:nth-child(3n + 1) {
              border-left: 1px solid #e4e3e3;
            }
            .approve-list-top {
              width: 100%;
              height: 463px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .approve-list-bottom {
              height: auto;
              padding: 0px 50px 67px;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              justify-content: center;
              flex: 1;
              .approve-list-title {
                font-size: 36px;
                line-height: 61px;
                text-align: center;
              }
            }
          }
        }
        .approve-list-more {
          height: 159px;
          font-size: 61px;
          color: #666666;
          line-height: 159px;
          text-align: center;
          margin-top: 40px;
        }
      }
      .contact-wrapper {
        .contact-list-wrapper {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .contact-list {
            width: 846px;
            height: 394px;
            background: #ffffff;
            box-shadow: 0px 0px 26px 0px #dde4f6;
            border-radius: 10px;
            padding: 95px 90px;
            box-sizing: border-box;
            margin-bottom: 79px;
            &:nth-child(n + 3) {
              margin-bottom: 0px;
            }
            .contact-list-title {
              margin-bottom: 38px;
              font-size: 41px;
              line-height: 67px;
            }
            .contact-list-desc {
              font-size: 46px;
              line-height: 61px;
            }
          }
        }
      }
      .affiliate-wrapper {
        margin-bottom: 80px;
        .affiliate-list-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .affiliate-list {
            width: 48%;
            margin-bottom: 25px;
            box-sizing: border-box;
            .affiliate-list-title {
              font-size: 72px;
              line-height: 84px;
              height: 141px;
              position: relative;
              &:before {
                content: "";
                display: block;
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 82px;
                height: 1px;
                background: #0070ff;
              }
            }
            .affiliate-list-address {
              font-size: 46px;
              line-height: 72px;
              height: 300px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
    .team-wrapper {
      .base-title {
        font-size: 82px;
        line-height: 123px;
        margin-top: 154px;
        margin-bottom: 64px;
      }
      .team-title {
        margin-bottom: 0px;
      }
      .team-desc {
        font-size: 56px;
        line-height: 66px;
        margin-top: 64px;
      }
      .team-list-wrapper {
        margin-top: 105px;
        font-size: 0px;
        padding-bottom: 48px;
        .team-list-top {
          display: flex;
          justify-content: space-between;
          .top-left-img,
          .top-right-img {
            border-radius: 5px;
            overflow: hidden;
            img {
              width: 100%;
              height: auto;
            }
          }
          .top-left-img {
            width: 1083px;
          }
          .top-right-img {
            width: 658px;
          }
        }
        .team-list-bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 26px;
          .team-bottom-list {
            width: 529px;
            border-radius: 5px;
            overflow: hidden;
            &:last-child {
              width: 658px;
            }
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
    .h5 {
      display: block;
    }
    .pc {
      display: none;
    }
  }
}
</style>
